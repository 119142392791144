<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Preloader from "@/components/widgets/preloader";
import Vue from "vue";
import Repository from "../../../app/repository/repository-factory";

const ImportRepository = Repository.get("ImportRepository");

    export default {
        components: {Layout, PageHeader, Preloader},
        data() {
            return {
                isBusy: false,
                preloader: false,
                title: "Import danych z pliku",
                items: [
                    {
                        text: 'Import',
                        active: true
                    }
                ],
                importInProgress: false,
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [10, 25, 50, 100],
                filter: "",
                filterOn: [''],
                sortBy: 'date',
                sortDesc: true,
                fields: [],
                submitted: false,
                form: {
                    aiProcessing: true,
                    name: null,
                    type: 'bank',
                    file: null,
                },
                typeOptions: [
                    {value: null, text: 'Wybierz źródło importu'},
                    {value: 'bank', text: 'Import z banku'},
                    {value: 'przelewy24', text: 'Przelewy24'},
                    {value: 'tpay', text: 'Tpay'},
                    {value: 'one_percent_us', text: '1% Urząd Skarbowy'},
                ]
            };
        },
        watch: {
            filter: function () {
                this.currentPage = 1;
            }
        },
        methods: {
            refreshGrid() {
                this.$refs['import-grid'].refresh();
            },
            validateSendFile() {
                let message = null;

                if (this.form.type === null) {
                    message = 'Musisz wybrać typ importu.';
                }

                if (this.form.file === null) {
                    message = 'Musisz wybrać plik do importu.';
                }

                if (message !== null) {
                    Vue.swal({
                        icon: "warning",
                        position: 'top',
                        title: "Uzupełnij dane!",
                        text: message,
                        showConfirmButton: false,
                        timer: 2000
                    });
                    return false;
                } else {
                    return true;
                }
            },
            uploadFile() {

                if (this.validateSendFile() === false) {
                    return false;
                }

                this.importInProgress = true;

                let formData = new FormData();
              formData.append('aiProcessing', this.form.aiProcessing);
                formData.append('file', this.form.file);
                formData.append('name', this.form.name);
                formData.append('type', this.form.type);

                ImportRepository.uploadFile(formData).then(() => {
                    Vue.swal({
                        icon: "success",
                        toast: false,
                        position: 'top',
                        title: "SUKCES! ",
                        text: 'Plik został zaimportowany!',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    this.refreshGrid();
                    this.importInProgress = false;
                    this.preloader = false;
                    this.form.file = null;
                }).catch(error => {
                    Vue.swal({
                        icon: "error",
                        position: 'top',
                        title: "Wystąpił błąd. Spróbuj jeszcze raz!",
                        text: error.response.data.message,
                        showConfirmButton: true
                    });
                    this.importInProgress = false;
                    this.preloader = false;
                })
            },
            axiosProvider(ctx) {

              const parameters = '?page=' + ctx.currentPage + '&maxItems=' + ctx.perPage + '&search=' + this.filter + '&orderBy=' + this.sortBy + '&orderDesc=' + this.sortDesc;
              const promise = ImportRepository.getGrid(parameters);

              return promise.then((data) => {
                const fields = data.data.info.fields;

                this.totalRows = data.data.info.countAllItems;

                let fieldsArray = [];
                Object.entries(fields).forEach(([key, val]) => {
                  if (val.disabled !== true) {
                    val.key = key;
                    fieldsArray.push(val);
                  }
                });

                fieldsArray.push({'key': 'operations', 'label': "Operacje"});
                fieldsArray.push({'key': 'assigned', 'label': "Op. przypisane"});
                fieldsArray.push({'key': 'booked', 'label': "Op. rozliczone"});
                fieldsArray.push({'key': 'action', 'label': "Akcje"});

                this.fields = fieldsArray;
              this.preloader = false;
              return data.data.data || []
            }
).catch(error => {
  console.log(error);
  return []
})
},
}
}
;
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <preloader v-if="preloader"></preloader>

    <div class="row">
      <div class="col-lg-12">
        <b-overlay :show="importInProgress" rounded="sm">
          <div class="card">
            <div class="card-body">
                            <h4 class="card-title">Import pliku</h4>
                            <p class="card-title-desc">
                                Wybierz plik do wgrania importu. Obsługiwane sa tylko pliki z konta bankowego i serwisu
                                Przelewy24. Po wybraniu plik zostanie zaimportowany.
                            </p>
                            <div class="row">
                                <div class="col-md-3 col-lg-3">
                                    <div class="form-group">
                                        <b-form-input
                                                id="input-1"
                                                v-model="form.name"
                                                type="text"
                                                placeholder="Wpisz nazwę importu (opcjonalnie)"
                                        ></b-form-input>
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <div class="form-group">
                                        <b-form-select
                                                v-model="form.type"
                                                :options="typeOptions">
                                        </b-form-select>
                                    </div>
                                </div>
                                <div class="col-md-4 col-lg-4">
                                    <div class="form-group">
                                        <b-form-file
                                                v-model="form.file"
                                                placeholder="Wybierz plik lub przeciągnij go tutaj..."
                                                drop-placeholder="Przeciągnij plik tutaj!"
                                                browse-text="Wybierz plik"
                                                accept=".csv, .xls, .xlsx">
                                        </b-form-file>
                                    </div>
                                </div>
                                <div class="col-md-2 col-lg-2">
                                    <div class="float-sm-left">
                                        <button type="button" v-on:click="uploadFile"
                                                class="btn btn-primary waves-effect waves-light">
                                            <i class="bx bx-import mr-1"></i> Importuj
                                            <template v-if="importInProgress">
                                                <b-spinner small class="ml-2" variant="light" role="status"></b-spinner>
                                            </template>
                                        </button>
                                    </div>
                                </div>
                              <div class="col-md-1 col-lg-1">
                                <div class="form-group" style="margin-top: 5px;">
                                  <b-form-checkbox
                                    id="aiProcessing"
                                    v-model="form.aiProcessing"
                                  >
                                    Użyj AI
                                  </b-form-checkbox>
                                </div>
                              </div>
                            </div>
                        </div>
                    </div>
                </b-overlay>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-sm-7 col-lg-6">
                                <b-form inline>
                                    <i v-on:click="refreshGrid" v-b-tooltip.hover title="Przeładuj dane"
                                       class="bx bx-sync font-size-24 mr-2"></i>
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    <div class="ml-2 search-box">
                                        <div class="position-relative">
                                            <b-form-input v-model="filter" :debounce="350"
                                                          placeholder="Szukaj..."></b-form-input>
                                            <i class="bx bx-search-alt search-icon"></i>
                                        </div>
                                    </div>
                                </b-form>
                            </div>
                            <div class="col-sm-5 col-lg-6">

                            </div>
                        </div>
                        <!-- End search -->
                        <!-- Table -->
                        <div class="table-responsive mb-0">
                            <b-table
                                    ref="import-grid"
                                    :items="axiosProvider"
                                    :fields="fields"
                                    responsive="sm"
                                    :per-page="perPage"
                                    :current-page="currentPage"
                                    :sort-by.sync="sortBy"
                                    :sort-desc.sync="sortDesc"
                                    :filter="filter"
                                    :filter-included-fields="filterOn">

                                <template v-slot:table-busy>
                                    <div class="text-center text-black">
                                        <b-spinner class="align-middle"></b-spinner>
                                        <strong> Ładowanie danych...</strong>
                                    </div>
                                </template>

                                <template v-slot:cell(operations)="data">
                                     <span class="badge badge-pill badge-soft-primary font-size-12"
                                           v-b-tooltip.hover
                                           :title="'Zaimportowanych: ' + data.item.countOperations.all">
                                         {{ data.item.countOperations.all }}
                                     </span>

                                    <template v-if="(data.item.countOperations.all - data.item.countOperations.allToAssigned) !== 0">
                                        /
                                        <span class="badge badge-pill badge-soft-danger font-size-12"
                                              v-b-tooltip.hover
                                              :title="'W tym zdublowanych: ' + (data.item.countOperations.all - data.item.countOperations.allToAssigned)">
                                              {{  data.item.countOperations.all - data.item.countOperations.allToAssigned }}
                                         </span>
                                    </template>
                                </template>

                              <template v-slot:cell(aiProcessing)="data">
                                        <span class="badge badge-pill badge-soft-success font-size-12"
                                              :class=" { 'badge-soft-danger': data.value == false,
                                                'badge-soft-success': data.value == true }">
                                                {{ data.value ? 'Tak' : 'Nie' }}</span>
                              </template>

                                <template v-slot:cell(assigned)="data">
                                     <span class="badge badge-pill badge-soft-success font-size-12"
                                           v-b-tooltip.hover
                                           :title="'Przypisanych operacji ' + data.item.countOperations.assigned + ' z ' + data.item.countOperations.allToAssigned"
                                           :class=" { 'badge-soft-danger': data.item.countOperations.assigned !== data.item.countOperations.allToAssigned,
                                                'badge-soft-success': data.item.countOperations.assigned === data.item.countOperations.allToAssigned }">
                                               {{ data.item.countOperations.assigned }} z {{ data.item.countOperations.allToAssigned }}
                                     </span>
                                </template>

                                <template v-slot:cell(booked)="data">
                                         <span class="badge badge-pill badge-soft-success font-size-12"
                                               v-b-tooltip.hover
                                               :title="'Rozliczonych operacji ' + data.item.countStatusBooked.booked + ' z ' + data.item.countOperations.allToAssigned"
                                               :class=" { 'badge-soft-danger': data.item.countStatusBooked.booked !== data.item.countOperations.allToAssigned,
                                                'badge-soft-success': data.item.countStatusBooked.booked === data.item.countOperations.allToAssigned }">
                                               {{ data.item.countStatusBooked.booked }} z {{data.item.countOperations.allToAssigned }}
                                     </span>
                                </template>

                                <template v-slot:cell(importBy)="data">
                                    {{ data.value.name }}
                                </template>

                                <template v-slot:cell(action)="row">
                                    <ul class="list-inline font-size-20 contact-links mb-0">
                                        <li class="list-inline-item px-2">
                                            <a v-b-tooltip.hover title="Przejście do listy operacji">
                                                <b-link :to="{ name: 'Operacje importu', params: { id: row.item.uuid } }">
                                                    <i class="bx bx-list-check"></i></b-link>
                                            </a>
                                        </li>
                                    </ul>
                                </template>

                            </b-table>
                        </div>
                        <div class="row">
                            <div class="col">
                                <template v-if="totalRows === 0">
                                    <div class="d-flex justify-content-center mt-3">
                                        <b>Nie znaleziono elementów</b>
                                    </div>
                                </template>
                                <template v-if="totalRows > 0">
                                    <div class="dataTables_paginate paging_simple_numbers float-left ml-2">
                                        Znaleziono {{ totalRows }} rekordów.
                                    </div>
                                </template>
                                <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                        <!-- pagination -->
                                        <b-pagination v-model="currentPage" :total-rows="totalRows"
                                                      :per-page="perPage"
                                                      first-number last-number></b-pagination>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
